@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
  @responsive {
    .text-shadow {
      text-shadow: 2px 2px 4px black;
    }
    .text-shadow-md {
      text-shadow: 4px 4px 8px black;
    }
    .text-shadow-lg {
      text-shadow: 15px 15px 30px black;
    }
    .text-shadow-none {
      text-shadow: none;
    }
  }
}

@layer base {
  input.reset, input.reset:focus, input.reset.form-control:focus {
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }

  a {
    @apply hover:underline
  }

  .show-line *, .show-line {
    border: 1px solid blue;
  }

  .container {
    @apply mx-auto max-w-[65rem] w-[95%] px-1 sm:px-0
  }

  .bg-business {
    background-color: #6fc7cc;
  }

  .text-business {
    color: #6fc7cc;
  }

  .border-business {
    border-color: #6fc7cc;
  }

  .text-link {
   @apply text-black underline
  }

  .opacity-98 {
    opacity: 0.98
  }

  .line-clamp-6 {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .bg-blur {
    background: linear-gradient(to top, #fff, rgba(255,255,255, 90%) 40%, rgba(255,255,255, 0) 100%);
  }

  .bg-blur-dark {
    background: linear-gradient(to top, #222, rgba(0,0,0, 0) 100%);
  }

  .shadow-full {
    box-shadow: 0 0 3px 1px rgba(0,0,0, .2);
  }
  .shadow-top {
    box-shadow: 0px -5px 5px -5px rgba(0,0,0, .2);
  }
  .modal-body-wrapper > div {
    height: 100%
  }
}
  .-z-1 { z-index: -1 }
